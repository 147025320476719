<style lang="scss" scoped>
.welcome {
	height: 100vh;
	box-sizing: border-box;
	padding: 0.3rem;
	text-align: center;
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
}
.welcome_text {
	font-size: 1rem;
	color: #fff;
	margin-top: 20vh;
}
.company_name {
	color: #fff;
	font-size: 0.6rem;
	line-height: 2rem;
}
.btn {
	margin-top: 10vh;
	padding: 0 1rem;
}
</style>

<template>
	<div class="welcome" :style="{ 'background-image': `url(${imgUrl})` }">
		<div class="welcome_text" v-text="mixin_userSetting.config6">欢迎</div>
		<section class="company_name" v-text="mixin_userSetting.config3">耀保网</section>
		<div class="btn">
			<van-button type="primary" round block @click="go">GO !</van-button>
		</div>
	</div>
</template>

<script>
import { Button } from 'vant';
import user from '@/components/user.js';
import { http_getNotificationById } from '@/request/notification.js';

export default {
	name: 'fixedCodeWelcome', // 欢迎页
	components: {
		[Button.name]: Button,
	},
	mixins: [user],
	data() {
		return {
			imgUrl: '',
		};
	},
	created() {
		this.init();
	},
	methods: {
		init() {
			this.$base.setUserTitle();
			this.mixin_login().then(() => {
				this.mixin_getUserSetting().then(setting => {
					this.getNotification(setting.config5);
					this.$base.setUserTitle(setting.config3);
				});
			});
		},

		// 获取告知书配置
		getNotification(id) {
			http_getNotificationById(id).then(res => {
				this.imgUrl = res.backImg;
			});
		},

		go() {
			this.$router.push({
				path: 'securityBook',
				query: {
					notificationId: this.mixin_userSetting.config5,
					companyName: this.mixin_userSetting.config4,
					nickname: this.mixin_userSetting.config3,
					...this.$route.query,
				},
			});
		},
	},
};
</script>
